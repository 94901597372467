import React from "react";
import { useFlags } from "../utils/FlagsProvider";
import Layout from "./Layout";
import OfficialWebCard from "./_OfficialWebCard";

export default function Jadwal() {
  const flags = useFlags();
  const jadwal = JSON.parse(flags.PPDB_JADWAL || "{}");

  return (
    <Layout>
      <div className="lg:px-80 sm:px-20 md:px-50 mt-5 mb-10">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Jadwal PPDB Gel. {flags?.PPDB_GELOMBANG} - Tahun Ajaran {flags?.PPDB_TAHUN_AJARAN}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Al Akhyar Islamic School.
            </p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Pendaftaran online/offline
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {+flags?.PPDB_GELOMBANG === 1 ? jadwal?.buka : jadwal?.batch2}
                </dd>
              </div>
              <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t-2 border-gray-200 border-dashed">
                <dt className="text-sm font-medium text-gray-500">
                  Observasi Calon Siswa*
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {Object.entries((+flags?.PPDB_GELOMBANG === 1 ? jadwal?.observasi : jadwal?.observasi_2)|| {})
                    .filter(([key, value]) => value !== false)
                    .map(([key, value]) => (
                      <div key={key}>
                        <p>
                          {key.toUpperCase().replace("IT", "")} : {value}
                        </p>
                      </div>
                    ))}
                </dd>
              </div>
              {jadwal?.pengumuman && (
                <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t-2 border-gray-200 border-dashed">
                  <dt className="text-sm font-medium text-gray-500">
                    Pengumuman hasil tes
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {jadwal?.pengumuman}
                  </dd>
                </div>
              )}
              <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t-2 border-gray-200 border-dashed">
                <dt className="text-sm font-medium text-gray-500">
                  Pendaftaran Ulang/Pengambilan Perlengkapan
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {jadwal?.heregistrasi}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t-2 border-gray-200 border-dashed">
                <dt className="text-sm font-medium text-gray-500">
                  Open House (Ukhuwah)
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {Object.entries(jadwal?.openhouse)
                    .filter(([key, value]) => value !== false)
                    .map(([key, value]) => (
                      <div key={key}>
                        <p>
                          {key.toUpperCase().replace("IT", "")} : {value}
                        </p>
                      </div>
                    ))}
                  <div className="text-gray-500 text-xs">
                    *Waktu dan Tempat tentatif sesuai jenjang masing-masing
                  </div>
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t-2 border-gray-200 border-dashed">
                <dt className="text-sm font-medium text-gray-500">
                  Kegiatan Masa Orientasi Siswa
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {Object.entries(jadwal?.mos)
                    .filter(([key, value]) => value !== false)
                    .map(([key, value]) => (
                      <div key={key}>
                        <p>
                          {key.toUpperCase().replace("IT", "")} : {value}
                        </p>
                      </div>
                    ))}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t-2 border-gray-200 border-dashed">
                <dt className="text-sm font-medium text-gray-500">
                  KBM dimulai
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {Object.entries(jadwal?.kbm)
                    .filter(([key, value]) => value !== false)
                    .map(([key, value]) => (
                      <div key={key}>
                        <p>
                          {key.toUpperCase().replace("IT", "")} : {value}
                        </p>
                      </div>
                    ))}
                </dd>
              </div>
              <div className="bg-primary px-4 py-5">
                <dt className="text-sm font-medium text-white text-center">
                  Gelombang II dibuka dari tanggal{" "}
                  <span className="text-lg font-bold">{jadwal?.batch2}</span>{" "}
                  jika kuota belum terpenuhi
                </dt>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <OfficialWebCard data={flags} />
    </Layout>
  );
}
