/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useFlags } from "../utils/FlagsProvider";
import BottomNavigation from "./BottomNavigation";
import Layout from "../components/Layout";
import PromoHero from "../components/_HeaderCard";
import OfficialWebCard from "./_OfficialWebCard";

const idr = (value) => {
  return value?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  });
};

const title = {
  tkit: "RA ",
  sdit: "SD",
  smpit: "SMP",
  smait: "SMA",
};

const colors = {
  tkit: "bg-secondary",
  sdit: "bg-info",
  smpit: "bg-primary",
  smait: "bg-slate-700",
};

const textColor = {
  tkit: "text-secondary",
  sdit: "text-info",
  smpit: "text-primary",
  smait: "text-slate-700",
};

const gradient = {
  tkit: "from-red-200 via-red-100",
  sdit: "from-blue-200 via-blue-100",
  smpit: "from-blue-200 via-blue-100",
  smait: "from-slate-200 via-slate-100",
};

const description = {
  tkit: "Pendidikan dini menerima <strong>anak usia 3-5 tahun</strong>. Pada usia ini fokus pada perkembangan motorik kasar dan halus, kemandirian, serta meningkatkan kemampuan sosial melalui bermain.",
  sdit: "Pendidikan dasar bagi <strong>anak usia diatas 6 tahun</strong> yang berfokus pada pembelajaran akademis dasar, keterampilan kejuruan, dan keterampilan sosialisasi.",
  smpit:
    "Pendidikan menengah bagi <strong>anak usia diatas 10 tahun</strong> yang berfokus pada pembelajaran akademis dasar, keterampilan kejuruan, dan keterampilan sosialisasi.",
  smait:
    "Pendidikan menengah atas bagi <strong>anak usia diatas 12</strong> tahun yang berfokus pada pembelajaran akademis dasar, keterampilan kejuruan, dan keterampilan sosialisasi.",
};

export default function Jenjang() {
  const param = useParams();
  const flags = useFlags();
  const syarats = JSON.parse(flags?.PPDB_SYARAT || "{}");
  const jadwal = JSON.parse(flags?.PPDB_JADWAL || "{}");
  const biaya = JSON.parse(flags?.PPDB_BIAYA || "{}");
  const keterangan = JSON.parse(flags?.PPDB_KETERANGAN || "{}");
  const berkas = JSON.parse(flags?.PPDB_BERKAS || "{}");
  const gelombang2 = `Gelombang II dibuka dari tanggal ${jadwal?.batch2} jika kuota belum terpenuhi`;
  const additional = JSON.parse(flags?.PPDB_ADDITIONAL_PROMO || "{}");
  const toggleBiaya = JSON.parse(flags?.PPDB_TOGGLE_BIAYA || "{}");

  const diskon = (gelombang) => {
    return biaya[param.id]?.gelombang?.find((item) => item.id === gelombang);
  };

  // console.log(additional)

  return (
    <Layout props={flags}>
      <Fragment>
        <PromoHero
          gradient={gradient[param.id]}
          name={title[param.id]}
          id={param.id}
          color={textColor[param.id]}
          description={description[param.id]}
        />

        <section className="" id="information">
          <div className="bg-white">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-top gap-y-16 gap-x-8 py-10 px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:grid-cols-3 lg:px-8">
              {/* <div className="col-span-3 lg:col-span-2">
              <div>
                <div className="flex flex-col lg:flex-row gap-5 lg:gap-10">
                  <div className="mt-1 text-center lg:text-left">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                      <span
                        className={`${
                          colors[param.id]
                        } text-white py-1 px-4 rounded-xl`}
                      >
                        {title[param.id]} Al Akhyar
                      </span>
                    </h2>
                    <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                      Islamic School
                    </h2>
                  </div>
                  <div
                    className={`${
                      colors[param.id]
                    } text-white p-4 px-10 rounded-xl text-center`}
                  >
                    <p className="text-xl mb-2 font-medium leading-none">
                      Promo{" "}
                      {+flags?.PPDB_GELOMBANG === 1
                        ? "Gelombang I"
                        : "Gelombang II"}
                    </p>
                    <p className="-mt-1.5 mb-2 text-sm">({jadwal?.buka})</p>
                    <p className="text-xs">Dapatkan Diskon hingga</p>
                    <div className="font-extrabold text-3xl lg:text-xl">
                      {+flags?.PPDB_GELOMBANG === 1
                        ? idr(biaya?.[param.id]?.diskon_1)
                        : idr(biaya?.[param.id]?.diskon_2)}
                      *
                    </div>
                    <p className="text-xs mt-2 text-gray-50 italic">
                      * Sesuai syarat dan ketentuan
                    </p>
                  </div>
                </div>
                <p
                  className={`mt-4 -mb-10 lg:text-left ${textColor[param.id]}`}
                >
                  {description[param.id]}
                </p>
              </div>
            </div> */}
              <div className="col-span-3">
                <dl className="grid grid-cols-1 gap-x-6 gap-y-10 place-content-center justify-center lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 sm:gap-y-10 lg:gap-x-6">
                  <dd className="pt-0 order-2 sm:order-1 lg:order-1">
                    {/* tk  */}
                    <div
                      className={`relative group flex justify-center lg:h-screen ${
                        param.id === "tkit" ? "" : "hidden"
                      }`}
                    >
                      <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 ">
                        <div className="flex flex-col items-center pb-5">
                          <h3 className="mb-1 text-2xl font-black tracking-wide text-secondary">
                            Syarat Pendaftaran
                          </h3>
                          <span className="text-sm text-gray-500 ">
                            Taman Kanak-Kanak Islam
                          </span>
                        </div>
                        <div className="flow-root">
                          <ul className="divide-y divide-gray-200 ">
                            <li className="py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="font-bold truncate text-md text-slate-700 ">
                                    Persyaratan Umum
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {syarats?.tkit?.map((item, index) => (
                                      <li
                                        key={index}
                                        className="text-sm text-gray-600 dark:text-gray-600"
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="font-bold truncate text-md text-slate-700 ">
                                    Kelengkapan Berkas Pendaftaran
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {berkas?.tkit?.map((item, index) => (
                                      <li
                                        key={index}
                                        className="text-sm text-gray-600 dark:text-gray-600"
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <p className="font-bold truncate text-md text-slate-700">
                                Catatan
                              </p>
                              <p className="text-sm text-gray-600 leading-1">
                                {gelombang2} ({jadwal?.kuota?.tkit} siswa).
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* end tk  */}

                    {/* sd  */}
                    <div
                      className={`relative group flex justify-center lg:h-screen ${
                        param.id === "sdit" ? "" : "hidden"
                      }`}
                    >
                      <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 ">
                        <div className="flex flex-col items-center pb-5">
                          <h3 className="mb-1 text-2xl font-black tracking-wide text-info">
                            Syarat Pendaftaran
                          </h3>
                          <span className="text-sm text-gray-500 ">
                            Sekolah Dasar Islam Terpadu
                          </span>
                        </div>
                        <div className="flow-root">
                          <ul className="divide-y divide-gray-200 ">
                            <li className="py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="font-bold truncate text-md text-slate-700 ">
                                    Persyaratan Umum
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {syarats?.sdit?.map((item, index) => (
                                      <li
                                        key={index}
                                        className="text-sm text-gray-600 dark:text-gray-600"
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="font-bold truncate text-md text-slate-700 ">
                                    Kelengkapan Berkas Pendaftaran
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {berkas?.sdit?.map((item, index) => (
                                      <li
                                        key={index}
                                        className="text-sm text-gray-600 dark:text-gray-600"
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <p className="font-bold truncate text-md text-slate-700">
                                Catatan
                              </p>
                              <p className="text-sm text-gray-600 leading-1">
                                {gelombang2} ({jadwal?.kuota?.sdit} siswa).
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* end sd  */}

                    {/* smp  */}
                    <div
                      className={`relative group flex justify-center lg:h-screen ${
                        param.id === "smpit" ? "" : "hidden"
                      }`}
                    >
                      <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 ">
                        <div className="flex flex-col items-center pb-5">
                          <h3 className="mb-1 text-2xl font-black tracking-wide text-sky-700 ">
                            Syarat Pendaftaran
                          </h3>
                          <span className="text-sm text-gray-500 ">
                            Sekolah Menengah Pertama Islam Terpadu
                          </span>
                        </div>
                        <div className="flow-root">
                          <ul className="divide-y divide-gray-200 ">
                            <li className="py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="font-bold truncate text-md text-slate-700 ">
                                    Persyaratan Umum
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {syarats?.smpit?.map((item, index) => (
                                      <li
                                        key={index}
                                        className="text-sm text-gray-600 dark:text-gray-600"
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="font-bold truncate text-md text-slate-700 ">
                                    Kelengkapan Berkas Pendaftaran
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {berkas?.smpit?.map((item, index) => (
                                      <li
                                        key={index}
                                        className="text-[13.5px] text-gray-600 dark:text-gray-600"
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <p className="font-bold truncate text-md text-slate-700">
                                Catatan
                              </p>
                              <p className="text-sm text-gray-600 leading-1">
                                {gelombang2} ({jadwal?.kuota?.smpit} siswa).
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* end smp  */}

                    {/* sma  */}
                    <div
                      className={`relative group flex justify-center lg:h-screen ${
                        param.id === "smait" ? "" : "hidden"
                      }`}
                    >
                      <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 ">
                        <div className="flex flex-col items-center pb-5">
                          <h3 className="mb-1 text-2xl font-black tracking-wide text-slate-700 ">
                            Syarat Pendaftaran
                          </h3>
                          <span className="text-sm text-gray-500 ">
                            Sekolah Menengah Atas Islam
                          </span>
                        </div>
                        <div className="flow-root">
                          <ul className="divide-y divide-gray-200 ">
                            <li className="py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="font-bold truncate text-md text-slate-700 ">
                                    Persyaratan Umum
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {syarats.smait?.map((item, index) => (
                                      <li
                                        key={index}
                                        className="text-sm text-gray-600 dark:text-gray-600"
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="font-bold truncate text-md text-slate-700 ">
                                    Kelengkapan Berkas Pendaftaran
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {berkas?.smait?.map((item, index) => (
                                      <li
                                        key={index}
                                        className="text-[13.5px] text-gray-600 dark:text-gray-600"
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <p className="font-bold truncate text-md text-slate-700">
                                Catatan
                              </p>
                              <p className="text-sm text-gray-600 leading-1">
                                {gelombang2} ({jadwal?.kuota?.smait} siswa).
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* end sma  */}
                  </dd>
                  {/* Biaya Pendidikan Section  */}
                  <dd className="pt-0 order-1 sm:order-2 lg:order-2">
                    
                    {!toggleBiaya ? (
                     <div className="max-w-md p-0 bg-white border rounded-lg shadow-md">
                     <img
                       src={`/ppdb/${param.id}.png`}
                       alt={param.id}
                       className="shadow rounded max-w-full h-auto align-middle border-none"
                     />
                   </div>
                    ): (
                      <>
                      <div
                      className={`relative group flex justify-center lg:h-screens ${
                        param.id === "tkit" ? "" : "hidden"
                      }`}
                    >
                      <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 ">
                        <div className="flex flex-col items-center pb-5">
                          <h3 className="mb-1 text-2xl font-black tracking-wide text-secondary">
                            Biaya Pendidikan
                          </h3>
                          <span className="text-sm text-gray-500 ">
                            Taman Kanak-Kanak Islam
                          </span>
                        </div>
                        <div className="flow-root">
                          <ul className="divide-y divide-gray-200 ">
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Formulir Pendaftaran
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.tkit?.formulir)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Uang Pangkal (A/B)
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.tkit?.uang_pangkal)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Infak Sarana TK A
                                  </p>
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Infak Sarana TK B
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.tkit?.infaq_sarpras_a)}
                                  <br />
                                  {idr(biaya?.tkit?.infaq_sarpras_b)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    SPP Bulanan (A/B)
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.tkit?.spp)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Alat dan Bahan (A/B)
                                  </p>
                                  <p className="text-sm text-gray-500 truncate ">
                                    Pembelajaran/Pertahun
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.tkit?.kegiatan)}
                                </div>
                              </div>
                            </li>
                            <li className="pt-3 pb-3 sm:pt-3">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-secondary">
                                    Biaya TK A <br />
                                    <span className="text-xs text-gray-500">
                                      *Sesuai ketentuan
                                    </span>
                                  </p>
                                  {/* <p className="text-xs font-normal text-gray-500">
                                  Dapatkan Diskon{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? "Gelombang I hingga "
                                    : "Gelombang II hingga "}{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? idr(biaya?.tkit?.diskon_1)
                                    : idr(biaya?.tkit?.diskon_2)}
                                  *
                                </p> */}
                                </div>
                                <div className="items-center text-base font-semibold text-secondary">
                                  <div className="text-xs font-normal text-gray-500 line-through">
                                    {idr(biaya?.tkit?.total_biaya_a)}
                                  </div>
                                  {idr(
                                    biaya?.tkit?.total_biaya_a -
                                      diskon(flags?.PPDB_GELOMBANG)?.diskon_umum
                                  )}
                                  *{/* {idr(biaya?.tkit?.total_biaya_a)} */}
                                </div>
                              </div>
                            </li>
                            <li className="pt-3 pb-3 sm:pt-3">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-secondary truncate ">
                                    Biaya TK B <br />
                                    <span className="text-xs text-gray-500">
                                      *Sesuai ketentuan
                                    </span>
                                  </p>
                                  {/* <p className="text-xs font-normal text-gray-500">
                                  Dapatkan Total Potongan{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? "Gelombang I hingga "
                                    : "Gelombang II hingga "}{" "}
                                  <span className="">{+flags?.PPDB_GELOMBANG === 1
                                    ? idr(biaya?.tkit?.diskon_1)
                                    : idr(biaya?.tkit?.diskon_2)}
                                  *</span>
                                </p> */}
                                </div>
                                <div className="items-start text-base font-semibold text-secondary">
                                  {/* {idr(biaya?.tkit?.total_biaya_b)} */}
                                  <div className="text-xs font-normal text-gray-500 line-through">
                                    {idr(biaya?.tkit?.total_biaya_b)}
                                  </div>
                                  {idr(
                                    biaya?.tkit?.total_biaya_b -
                                      diskon(flags?.PPDB_GELOMBANG)?.diskon_umum
                                  )}
                                  *
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2 bg-secondary text-white px-4 rounded-lg hidden">
                              <div
                                className={`${
                                  colors[param.id]
                                } text-white p-2 rounded-xl text-center`}
                              >
                                <p className="text-xl mb-2 font-medium">
                                  Promo{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? "Gelombang I"
                                    : "Gelombang II"}
                                </p>
                                <p className="-mt-1.5 mb-2 text-sm">
                                  (
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? jadwal?.buka
                                    : jadwal?.batch2}
                                  )
                                </p>
                                <p className="text-xs">
                                  Dapatkan Diskon hingga
                                </p>
                                <div className="font-extrabold text-3xl lg:text-xl">
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? idr(biaya?.[param.id]?.diskon_1)
                                    : idr(biaya?.[param.id]?.diskon_2)}
                                  *
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4 mb-2">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    }`}
                                  >
                                    *Syarat dan Ketentuan Diskon{" "}
                                    {+flags?.PPDB_GELOMBANG === 1
                                      ? "Early Bird - Gelombang I"
                                      : "Gelombang II"}
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    <img
                                      className="hover:scale-150 transition duration-500 cursor-pointer"
                                      src={
                                        biaya?.tkit?.discon_image ??
                                        "https://fakeimg.pl/350x150?text=😊"
                                      }
                                      alt={param.id}
                                    />
                                  </ul>
                                </div>
                              </div>
                              <Additional {...additional} />
                            </li>
                            {/* <li className="py-2 sm:py-2">
                            <div className="flex items-center space-x-4">
                              <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-secondary truncate ">
                                  Potongan
                                </p>
                                <ul className="list-decimal pl-4">
                                  {biaya?.tkit?.diskon?.map((item, index) => (
                                    <li
                                      key={index}
                                      className="text-xs text-gray-600 dark:text-gray-600"
                                    >
                                      {item}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </li> */}
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    } truncate`}
                                  >
                                    Keterangan
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {/* <li className='text-xs text-gray-600'>{keterangan?.test_masuk?.sdit}</li> */}
                                    {keterangan?.perlengkapan?.tkit?.map(
                                      (item, index) => (
                                        <li
                                          className="text-xs text-gray-600"
                                          key={index}
                                        >
                                          {item}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`relative group flex justify-center lg:h-screens ${
                        param.id === "sdit" ? "" : "hidden"
                      }`}
                    >
                      <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 ">
                        <div className="flex flex-col items-center pb-5">
                          <h3 className="mb-1 text-2xl font-black tracking-wide text-info">
                            Biaya Pendidikan
                          </h3>
                          <span className="text-sm text-gray-500 ">
                            Sekolah Dasar Islam
                          </span>
                        </div>
                        <div className="flow-root">
                          <ul className="divide-y divide-gray-200 ">
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Formulir Pendaftaran
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.sdit?.formulir)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Uang Pangkal
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.sdit?.uang_pangkal)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Infak Pemeliharaan
                                  </p>
                                  <p className="text-sm text-gray-500 truncate ">
                                    Sarana dan Prasarana
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.sdit?.infaq_sarpras)}
                                </div>
                              </div>
                            </li>

                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    SPP Bulanan
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.sdit?.spp)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Dana Kegiatan
                                  </p>
                                  <p className="text-sm text-gray-500 truncate ">
                                    Pertahun
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.sdit?.kegiatan)}
                                </div>
                              </div>
                            </li>
                            <li className="pt-3 pb-4 sm:pt-4">
                              <div className="flex items-start space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-info">
                                    Biaya SD (Alumni TK)
                                    <br />
                                    <span className="text-xs text-gray-500">
                                      *Sesuai ketentuan
                                    </span>
                                  </p>
                                  {/* <p className="text-xs text-gray-500">
                                  Dapatkan Total Potongan{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? "Gelombang I hingga "
                                    : "Gelombang II hingga "}{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? idr(biaya?.sdit?.diskon_1)
                                    : idr(biaya?.sdit?.diskon_2)}
                                  *
                                </p> */}
                                </div>
                                <div className="items-center text-base font-semibold text-info">
                                  {/* {idr(biaya?.sdit?.total_biaya)} */}
                                  <div className="text-gray-500 text-xs line-through">
                                    {idr(biaya?.sdit?.total_biaya)}
                                  </div>
                                  {idr(
                                    biaya?.sdit?.total_biaya -
                                      diskon(flags?.PPDB_GELOMBANG)
                                        ?.diskon_alumni
                                  )}
                                  *
                                </div>
                              </div>
                            </li>
                            <li className="pt-3 pb-4 sm:pt-4">
                              <div className="flex items-start space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-info">
                                    Biaya SD (Umum)
                                    <br />
                                    <span className="text-xs text-gray-500">
                                      *Sesuai ketentuan
                                    </span>
                                  </p>
                                </div>
                                <div className="items-center text-base font-semibold text-info">
                                  {/* {idr(biaya?.sdit?.total_biaya)} */}
                                  <div className="text-gray-500 text-xs line-through">
                                    {idr(biaya?.sdit?.total_biaya)}
                                  </div>
                                  {idr(
                                    biaya?.sdit?.total_biaya -
                                      diskon(flags?.PPDB_GELOMBANG)?.diskon_umum
                                  )}
                                  *
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2 bg-info text-white px-4 rounded-lg hidden">
                              <div
                                className={`${
                                  colors[param.id]
                                } text-white p-2 rounded-xl text-center`}
                              >
                                <p className="text-xl mb-2 font-medium">
                                  Promo{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? "Gelombang I"
                                    : "Gelombang II"}
                                </p>
                                <p className="-mt-1.5 mb-2 text-sm">
                                  (
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? jadwal?.buka
                                    : jadwal?.batch2}
                                  )
                                </p>
                                <p className="text-xs">
                                  Dapatkan Diskon hingga
                                </p>
                                <div className="font-extrabold text-3xl lg:text-xl">
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? idr(biaya?.[param.id]?.diskon_1)
                                    : idr(biaya?.[param.id]?.diskon_2)}
                                  *
                                </div>
                                {/* <p className="text-xs mt-2 text-gray-50 italic">
                                * Sesuai syarat dan ketentuan
                              </p> */}
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4 mb-3">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-info truncate ">
                                    *Syarat dan Ketentuan Diskon{" "}
                                    {+flags?.PPDB_GELOMBANG === 1
                                      ? "Early Bird - Gelombang I"
                                      : "Gelombang II"}
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    <img
                                      className="hover:scale-150 transition duration-500 cursor-pointer"
                                      src={
                                        biaya?.sdit?.discon_image ??
                                        "https://fakeimg.pl/350x150?text=😊"
                                      }
                                      alt={param.id}
                                    />
                                  </ul>
                                </div>
                              </div>
                              <Additional {...additional} />
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    }`}
                                  >
                                    Potongan Biaya SPP Bagi Tahfizd Qur'an
                                    hingga {idr(biaya?.sdit?.tahfidz)}
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    Diberikan/dimulai setelah ujian tahfidz dan
                                    besaran sesuai ketentuan Yayasan
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    } truncate`}
                                  >
                                    Informasi Lainnya
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {/* <li className='text-xs text-gray-600'>{keterangan?.test_masuk?.sdit}</li> */}
                                    {keterangan?.perlengkapan?.sdit?.map(
                                      (item, index) => (
                                        <li
                                          className="text-xs text-gray-600"
                                          key={index}
                                        >
                                          {item}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`relative group flex justify-center lg:h-screens ${
                        param.id === "smpit" ? "" : "hidden"
                      }`}
                    >
                      <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 ">
                        <div className="flex flex-col items-center pb-5">
                          <h3 className="mb-1 text-2xl font-black tracking-wide text-primary ">
                            Biaya Pendidikan
                          </h3>
                          <span className="text-sm text-gray-500 ">
                            Sekolah Menengah Pertama
                          </span>
                        </div>
                        <div className="flow-root">
                          <ul className="divide-y divide-gray-200 ">
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Formulir Pendaftaran
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smpit?.formulir)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Uang Pangkal
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smpit?.uang_pangkal)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Infak Pemeliharaan
                                  </p>
                                  <p className="text-sm text-gray-500 truncate ">
                                    Sarana dan Prasarana
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smpit?.infaq_sarpras)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Biaya OSIS/tahun
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smpit?.mos)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    SPP Bulanan
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smpit?.spp)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Dana Kegiatan
                                  </p>
                                  <p className="text-sm text-gray-500 truncate ">
                                    Pertahun
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smpit?.kegiatan)}
                                </div>
                              </div>
                            </li>
                            <li className="pt-3 pb-4 sm:pt-4">
                              <div className="flex items-start space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    }`}
                                  >
                                    Biaya SMP (Alumni SD)
                                    <br />
                                    <span className="text-xs text-gray-500">
                                      *Sesuai ketentuan
                                    </span>
                                  </p>
                                  {/* <p className="text-xs text-gray-500">
                                  Dapatkan Total Potongan{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? "Gelombang I hingga "
                                    : "Gelombang II hingga "}{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? idr(biaya?.smpit?.diskon_1)
                                    : idr(biaya?.smpit?.diskon_2)}
                                  *
                                </p> */}
                                </div>
                                <div
                                  className={`text-base font-bold ${
                                    textColor[param.id]
                                  }`}
                                >
                                  <div className="text-gray-500 text-xs line-through">
                                    {idr(biaya?.smpit?.total_biaya)}
                                  </div>
                                  {idr(
                                    biaya?.smpit?.total_biaya -
                                      diskon(flags?.PPDB_GELOMBANG)
                                        ?.diskon_alumni
                                  )}
                                  *{/* {idr(biaya?.smpit?.total_biaya)} */}
                                </div>
                              </div>
                            </li>
                            <li className="pt-3 pb-4 sm:pt-4">
                              <div className="flex items-start space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    }`}
                                  >
                                    Biaya SMP (Umum)
                                    <br />
                                    <span className="text-xs text-gray-500">
                                      *Sesuai ketentuan
                                    </span>
                                  </p>
                                </div>
                                <div
                                  className={`text-base font-bold ${
                                    textColor[param.id]
                                  }`}
                                >
                                  <div className="text-gray-500 text-xs line-through">
                                    {idr(biaya?.smpit?.total_biaya)}
                                  </div>
                                  {idr(
                                    biaya?.smpit?.total_biaya -
                                      diskon(flags?.PPDB_GELOMBANG)?.diskon_umum
                                  )}
                                  * {/* {idr(biaya?.smpit?.total_biaya)} */}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2 bg-primary text-white px-4 rounded-lg hidden">
                              <div
                                className={`${
                                  colors[param.id]
                                } text-white p-2 rounded-xl text-center`}
                              >
                                <p className="text-xl mb-2 font-medium">
                                  Promo{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? "Gelombang I"
                                    : "Gelombang II"}
                                </p>
                                <p className="-mt-1.5 mb-2 text-sm">
                                  (
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? jadwal?.buka
                                    : jadwal?.batch2}
                                  )
                                </p>
                                <p className="text-xs">
                                  Dapatkan Diskon hingga
                                </p>
                                <div className="font-extrabold text-3xl lg:text-xl">
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? idr(biaya?.[param.id]?.diskon_1)
                                    : idr(biaya?.[param.id]?.diskon_2)}
                                  *
                                </div>
                                {/* <p className="text-xs mt-2 text-gray-50 italic">
                                * Sesuai syarat dan ketentuan
                              </p> */}
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4 mb-2">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    }`}
                                  >
                                    *Syarat dan Ketentuan Diskon{" "}
                                    {+flags?.PPDB_GELOMBANG === 1
                                      ? "Early Bird - Gelombang I"
                                      : "Gelombang II"}
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    <img
                                      className="hover:scale-150 transition duration-500 cursor-pointer"
                                      src={
                                        biaya?.smpit?.discon_image ??
                                        "https://fakeimg.pl/350x150?text=😊"
                                      }
                                      alt={param.id}
                                    />
                                  </ul>
                                </div>
                              </div>
                              <Additional {...additional} />
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    }`}
                                  >
                                    Potongan Biaya SPP Bagi Tahfizd Qur'an
                                    hingga {idr(biaya?.smpit?.tahfidz)}
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    Diberikan/dimulai setelah ujian tahfidz dan
                                    besaran sesuai ketentuan Yayasan
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    } truncate`}
                                  >
                                    Informasi Lainnya
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {/* <li className='text-xs text-gray-600'>{keterangan?.test_masuk?.sdit}</li> */}
                                    {keterangan?.perlengkapan?.smpit?.map(
                                      (item, index) => (
                                        <li
                                          className="text-xs text-gray-600"
                                          key={index}
                                        >
                                          {item}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`relative group flex justify-center lg:h-screens ${
                        param.id === "smait" ? "" : "hidden"
                      }`}
                    >
                      <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 ">
                        <div className="flex flex-col items-center pb-5">
                          <h3 className="mb-1 text-2xl font-black tracking-wide text-slate-600 ">
                            Biaya Pendidikan
                          </h3>
                          <span className="text-sm text-gray-500 ">
                            Sekolah Menengah Atas
                          </span>
                        </div>
                        <div className="flow-root">
                          <ul className="divide-y divide-gray-200 ">
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Formulir Pendaftaran
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smait?.formulir)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Uang Pangkal
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smait?.uang_pangkal)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Infak Pemeliharaan
                                  </p>
                                  <p className="text-sm text-gray-500 truncate ">
                                    Sarana dan Prasarana
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smait?.infaq_sarpras)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Biaya OSIS/tahun
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smait?.mos)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    SPP Bulanan
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smait?.spp)}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate ">
                                    Dana Kegiatan
                                  </p>
                                  <p className="text-sm text-gray-500 truncate ">
                                    Pertahun
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                  {idr(biaya?.smait?.kegiatan)}
                                </div>
                              </div>
                            </li>
                            <li className="pt-3 pb-4 sm:pt-4">
                              <div className="flex items-start space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    }`}
                                  >
                                    Biaya SMA (Alumni SMP)
                                    <br />
                                    <span className="text-xs text-gray-500">
                                      *Sesuai ketentuan
                                    </span>
                                  </p>
                                  {/* <p className="text-xs text-gray-500">
                                  Dapatkan Total Potongan{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? "Gelombang I hingga "
                                    : "Gelombang II hingga "}{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? idr(biaya?.smait?.diskon_1)
                                    : idr(biaya?.smait?.diskon_2)}
                                  *
                                </p> */}
                                </div>
                                <div
                                  className={`text-base font-bold ${
                                    textColor[param.id]
                                  }`}
                                >
                                  <div className="text-gray-500 text-xs line-through">
                                    {idr(biaya?.smait?.total_biaya)}
                                  </div>
                                  {idr(
                                    biaya?.smait?.total_biaya -
                                      diskon(flags?.PPDB_GELOMBANG)
                                        ?.diskon_alumni
                                  )}
                                  *{/* {idr(biaya?.smait?.total_biaya)} */}
                                </div>
                              </div>
                            </li>
                            <li className="pt-3 pb-4 sm:pt-4">
                              <div className="flex items-start space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    }`}
                                  >
                                    Biaya SMA (Umum)
                                    <br />
                                    <span className="text-xs text-gray-500">
                                      *Sesuai ketentuan
                                    </span>
                                  </p>
                                  {/* <p className="text-xs text-gray-500">
                                  Dapatkan Total Potongan{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? "Gelombang I hingga "
                                    : "Gelombang II hingga "}{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? idr(biaya?.smait?.diskon_1)
                                    : idr(biaya?.smait?.diskon_2)}
                                  *
                                </p> */}
                                </div>
                                <div
                                  className={`text-base font-bold ${
                                    textColor[param.id]
                                  }`}
                                >
                                  <div className="text-gray-500 text-xs line-through">
                                    {idr(biaya?.smait?.total_biaya)}
                                  </div>
                                  {idr(
                                    biaya?.smait?.total_biaya -
                                      diskon(flags?.PPDB_GELOMBANG)?.diskon_umum
                                  )}
                                  *{/* {idr(biaya?.smait?.total_biaya)} */}
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2 bg-gray-700 text-white px-4 rounded-lg hidden">
                              <div
                                className={`${
                                  colors[param.id]
                                } text-white p-2 rounded-xl text-center`}
                              >
                                <p className="text-xl mb-2 font-medium">
                                  Promo{" "}
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? "Gelombang I"
                                    : "Gelombang II"}
                                </p>
                                <p className="-mt-1.5 mb-2 text-sm">
                                  (
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? jadwal?.buka
                                    : jadwal?.batch2}
                                  )
                                </p>
                                <p className="text-xs">
                                  Dapatkan Diskon hingga
                                </p>
                                <div className="font-extrabold text-3xl lg:text-xl">
                                  {+flags?.PPDB_GELOMBANG === 1
                                    ? idr(biaya?.[param.id]?.diskon_1)
                                    : idr(biaya?.[param.id]?.diskon_2)}
                                  *
                                </div>
                                {/* <p className="text-xs mt-2 text-gray-50 italic">
                                * Sesuai syarat dan ketentuan
                              </p> */}
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4 mb-2">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    }`}
                                  >
                                    *Syarat dan Ketentuan Diskon{" "}
                                    {+flags?.PPDB_GELOMBANG === 1
                                      ? "Early Bird - Gelombang I"
                                      : "Gelombang II"}
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    <img
                                      className="hover:scale-150 transition duration-500 cursor-pointer"
                                      src={
                                        biaya?.smait?.discon_image ??
                                        "https://fakeimg.pl/350x150?text=😊"
                                      }
                                      alt={param.id}
                                    />
                                  </ul>
                                </div>
                              </div>
                              <Additional {...additional} />
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    }`}
                                  >
                                    Potongan Biaya SPP Bagi Tahfizd Qur'an
                                    hingga {idr(biaya?.smait?.tahfidz)}
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    Diberikan/dimulai setelah ujian tahfidz dan
                                    besaran sesuai ketentuan Yayasan
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="py-2 sm:py-2">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`text-sm font-medium ${
                                      textColor[param.id]
                                    } truncate`}
                                  >
                                    Informasi Lainnya
                                  </p>
                                  <ul className="list-decimal pl-4">
                                    {/* <li className='text-xs text-gray-600'>{keterangan?.test_masuk?.sdit}</li> */}
                                    {keterangan?.perlengkapan?.smait?.map(
                                      (item, index) => (
                                        <li
                                          className="text-xs text-gray-600"
                                          key={index}
                                        >
                                          {item}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                      </>
                    )}
                  </dd>
                  <div className="pt-0 order-3 sm:order-3 lg:order-3">
                    <dd>
                      <div className="grid justify-center group">
                        <div className="max-w-md mb-5 bg-white border rounded-lg shadow-md p-6">
                          <div className="">
                            <div className="text-left">
                              <div className="mb-0 text-sm">
                                Pembayaran melalui transfer
                              </div>
                              <ul className="divide-y divide-gray-200 ">
                                {param.id === "tkit" &&
                                  keterangan?.pembayaran?.tkit?.map(
                                    (item, index) => (
                                      <li key={index} className="py-2 sm:py-2">
                                        <div className="flex items-top space-x-4">
                                          <div className="flex-1 min-w-0">
                                            <p className="text-sm font-base text-gray-900">
                                              <span className="font-bold">
                                                Bank {item.bank}
                                              </span>{" "}
                                              a.n {item.penerima}
                                            </p>
                                            <p className="text-lg font-bold text-gray-900">
                                              {item.rekening}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                                {param.id !== "tkit" &&
                                  keterangan?.pembayaran?.official?.map(
                                    (item, index) => (
                                      <li key={index} className="py-2 sm:py-2">
                                        <div className="flex items-top space-x-4">
                                          <div className="flex-1 min-w-0">
                                            <p className="text-sm font-base text-gray-900">
                                              <span className="font-bold">
                                                Bank {item.bank}
                                              </span>{" "}
                                              a.n {item.penerima}
                                            </p>
                                            <p className="text-lg font-bold text-gray-900">
                                              {item.rekening}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>

                            <figure className="max-w-screen-md mt-1 border-t-2 pt-3">
                              <blockquote>
                                <p
                                  className={`text-sm font-semibold ${
                                    textColor[param.id]
                                  }`}
                                >
                                  {keterangan?.resi}
                                </p>
                              </blockquote>
                            </figure>
                          </div>
                        </div>
                        {toggleBiaya && (<div className="max-w-md p-0 bg-white border rounded-lg shadow-md">
                          <img
                            src={`/ppdb/${param.id}.png`}
                            alt={param.id}
                            className="shadow rounded max-w-full h-auto align-middle border-none"
                          />
                        </div>)}
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>

            <div className="p-8 mb-8 mx-auto w-full max-w-screen-xl bg-blue-100 text-primary text-center rounded-lg sm:p-8 dark:bg-gray-800 dark:border-gray-700">
              <h5 className="mb-2 text-3xl font-bold dark:text-white">
                Penerimaan Peserta Didik Baru {flags.PPDB_TAHUN_AJARAN}
              </h5>
              <p className="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">
                Tetap mendapatkan informasi terkini mengenai PPDB Al Akhyar
                Islamic School di WhatsApp. <br />
                Segera daftarkan putra-putri Anda hari ini.
              </p>
              <div className="justify-center items-center space-y-2 sm:flex sm:space-y-0 sm:space-x-4 mb-3">
                <a
                  href={`https://wa.me/${
                    param.id === "tkit"
                      ? keterangan?.whatsApp?.tkit
                      : keterangan?.whatsApp?.official
                  }`}
                  className="w-full lg:w-auto order-2 sm:w-auto bg-[#075E54] hover:bg-[#075E54]/90 focus:ring-4 focus:outline-none focus:ring-[#075E54]/50 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-[#075E54] dark:hover:bg-[#075E54]/50 dark:focus:ring-[#075E54]/55"
                >
                  <svg
                    className="mr-3 w-7 h-7 text-white fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                  </svg>
                  <div className="text-left">
                    <div className="mb-1 text-xs">Hubungi kami</div>
                    <div className="-mt-1 font-sans text-sm font-semibold">
                      WhatsApp
                    </div>
                  </div>
                </a>
                <a
                  href="https://s.alakhyar.sch.id/ppdb"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="w-full lg:w-auto order-1 lg:order-2 sm:w-auto bg-blue-800 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-blue-700 dark:hover:bg-blue-600 dark:focus:ring-blue-700"
                >
                  <svg
                    className="w-7 h-7 mr-3"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                    ></path>
                  </svg>
                  <div className="text-left">
                    <div className="mb-1 text-xs">Ayo Buruan</div>
                    <div className="-mt-1 font-sans text-sm font-semibold">
                      Daftar Segera
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <OfficialWebCard />
          <BottomNavigation color={textColor[param.id]} />
        </section>
      </Fragment>
    </Layout>
  );
}

function Additional(props) {
  return (
    <>
      <div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-4 py-3 bg-red-700 border-b">
          <h2 className="text-lg font-semibold text-white">
            {props?.title} {props?.promo}
          </h2>
        </div>
        <div className="p-4">
          <p className="text-gray-700 text-sm">
            Voucher ini memberikan hak untuk:
          </p>
          <div className="mt-2 border-t border-gray-200 pt-2">
            <p className="text-gray-600 text-xs">
              - Diskon formulir pendafataran sebesar {props.diskon?.formulir}
            </p>
            <p className="text-gray-600 text-xs">
              - Diskon biaya pendidikan sebesar {props.diskon?.biaya_pendidikan}
            </p>
          </div>
          <div className="mt-4">
            <p className="text-gray-700 text-sm">
              Berlaku hingga:{" "}
              <span className="font-semibold">{props?.subtitle}</span>
            </p>
          </div>
          <div className="mt-4">
            <p className="text-gray-700 text-sm">
              Promo Code:{" "}
              <span className="font-semibold tracking-wider text-lg">
                {props?.voucher}
              </span>
            </p>
          </div>
          <div className="mt-6">
            <p className="text-xs text-gray-500">
              * Terms and conditions apply
            </p>
          </div>
        </div>
      </div>

    </>
  );
}
