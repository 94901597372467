import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Closed()
{
    return (
        <>
        <div className="flex items-center justify-center w-screen h-screen">
            <div className="px-4 lg:py-12">
                <div className="lg:gap-4 lg:flex">
                <div className="flex flex-col items-center justify-center md:py-24 lg:py-32">
                    <h1 className="font-bold text-primary text-6xl">PPDB Ditutup!</h1>
                    {/* <p className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
                        <span className="text-red-500">Oops!</span> Page not found
                    </p> */}
                    <p className="mb-8 text-center text-gray-500 md:text-lg px-20">
                    PPDB tahun ajaran baru akan dibuka kembali setiap akhir tahun. Pantau terus informasi terkini di sosial media kami.
                    </p>
                    <NavLink className="px-6 py-3 text-xl font-semibold bg-blue-100 rounded-3xl text-primary" to="/">
                        Back to home
                    </NavLink>  
                </div>
                <div className="mt-4">
                    <img
                    src="/img/close.png"
                    alt="img"
                    className="object-cover w-full h-full px-10"
                    />
                </div>
                </div>
            </div>
        </div>
        </>
    )
}