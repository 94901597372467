import React from 'react';
import { useNavigate } from "react-router-dom"
  
function Feature() {
  let navigate = useNavigate();
    const click = (e) => {
      navigate(`/jenjang/${e}`)
    }

    return (
      <div className="bg-slate-50" id="feature">
        <div className="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
          <div>
          <h1 className="text-4xl font font-extrabold tracking-tight text-primary sm:text-6xl">
                Jenjang pendidikan yang lengkap
              </h1>
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl mt-3">TK, SD, SMP dan SMA Islam.</h2>
            <p className="mt-4 text-gray-500" >
              Al Akhyar Islamic School Makassar sangat tepat sebagai tempat pendidikan yang sesuai bagi segala jenjang pendidikan.
            </p>
          
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            {/* <img
              src="https://tailwindui.com/img/ecommerce-images/product-feature-03-detail-01.jpg"
              alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
              className="bg-gray-100 rounded-lg"
            /> */}

            <button onClick={() => click('tkit')} className="inline-flex px-10 items-center justify-center w-100 h-100 py-20 font-black mr-2 text-secondary transition-colors duration-150 bg-red-200 rounded-3xl focus:shadow-outline hover:bg-secondary hover:text-white sm:text-3xl md:text-5xl text-3xl ">
              TK Islam
            </button>
            <button onClick={() => click('sdit')} className="text-3xl inline-flex px-10 items-center justify-center w-100 h-100 py-20 font-black mr-2 text-info transition-colors duration-150 bg-blue-200 rounded-3xl focus:shadow-outline hover:bg-info hover:text-white sm:text-3xl md:text-5xl">
              SD Islam
            </button>
            <button onClick={() => click('smpit')} className="inline-flex px-4 items-center justify-center w-100 h-100 py-20 font-black mr-2 text-primary transition-colors duration-150 bg-blue-200 rounded-3xl focus:shadow-outline hover:bg-primary hover:text-white sm:text-3xl md:text-5xl text-3xl ">
              SMP Islam
            </button>
            <button onClick={() => click('smait')} className="inline-flex px-4 items-center justify-center w-100 h-100 py-20 font-black mr-2 text-slate-700 transition-colors duration-150 bg-slate-200 rounded-3xl focus:shadow-outline hover:bg-slate-700 hover:text-white sm:text-3xl md:text-5xl text-3xl">
              SMA Islam
            </button>
          </div>
        </div>
      </div>
    )
  }

  export default Feature;
  