/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
// import semverSatisfies from 'semver/functions/satisfies';
import { firebase } from './firebase';
import { getRemoteConfig, getAll, fetchAndActivate } from "firebase/remote-config";

const FlagsContext = React.createContext({});
const remoteConfig = getRemoteConfig(firebase);

export const useFlags = () => {
  const context = React.useContext(FlagsContext);
  return context;
}

// For development only
remoteConfig.settings.minimumFetchIntervalMillis = 0;

const FlagsProvider = ({ defaults, children }) => {
  const [flags, setFlags] = useState(defaults);

  useEffect(() => {
    remoteConfig.defaultConfig = defaults;

    fetchAndActivate(remoteConfig)
    .then(() => {
      const newFlags = {
        ...flags,
      }

        for (const [key, config] of Object.entries(getAll(remoteConfig))) {
          newFlags[key] = config._value;
        }

        setFlags(newFlags);

    })
    .catch((err) => {
      console.log(err)
    });
  }, []);

  // console.log('flags', flags)

  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
};

export default FlagsProvider;