import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import BottomNavigation from "./BottomNavigation";
import { useFlags } from "../utils/FlagsProvider";


const Layout = ({props,  children }) => {
  const flags = useFlags();

  return (
    <Fragment>
      <Header flag={flags}/>
      <main>{children}</main>
      <Footer />
      <BottomNavigation/>
    </Fragment>
  );
};

export default Layout;