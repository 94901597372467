import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MyApp from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Jenjang from './components/Jenjangs';
import Jadwal from './components/Jadwal';
import NotFound from './components/NotFound';
import ScrollToTop from './helper/ScrollToTop';
import FlagsProvider from './utils/FlagsProvider';
import Closed from './components/Closed';

import ReactGA from 'react-ga';
const TRACKING_ID = "G-KWX89Y57DC";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    // Wrap the entire application with ReactGA.pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <FlagsProvider
      defaults={{
        PPDB_TAHUN_AJARAN: '2023/2024',
      }}
    >
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MyApp />} />
          <Route exact path="jenjang/:id" element={<Jenjang />} />
          <Route exact path="jadwal" element={<Jadwal />} />
          <Route path="/closed" element={<Closed />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </FlagsProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
