export default function HeaderCard(props) {
  return (
    <>
      <div className="max-w-[75rem] mt-6 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
          <div>
            <div className="relative">
              <span
                id="happy-bg"
                className={`absolute rounded-lg inset-0 left-0 h-10 -mt-1 lg:-mt-0 lg:h-16 -rotate-[1.8deg] lg:-rotate-[2.2deg]  bg-gradient-to-r to-transparent opacity-100 w-4/5 ${props.gradient}`}
              ></span>
              <span className={`relative z-10 text-3xl p-2 font-bold sm:text-4xl lg:text-6xl lg:leading-tight ${props.color}`}>
                {props.name} Al Akhyar
              </span>
            </div>
            
            <p dangerouslySetInnerHTML={{ __html: props.description }} className="mt-3 text-lg">
            </p>

            <div className="mt-7 grid gap-3 w-full sm:inline-flex">
              <a
                className="inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-sm lg:text-base text-white font-medium rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800"
                href="https://s.alakhyar.sch.id/ppdb"
              >
                Daftar Sekarang
                <svg
                  className="w-2.5 h-2.5"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </a>
            </div>
          </div>

          <div className="relative ml-4">
            <img
              className="w-full rounded-md shadow-md"
              src={`/ppdb/${props.id}-brosur.png`}
              alt="first"
            />

            <div className={`absolute inset-0 -z-[1] bg-gradient-to-tr to-white/0 w-full h-full rounded-md mt-4 mr-4 -ml-4 lg:mt-6 lg:-mb-6 lg:mr-6 lg:-ml-6  ${props.gradient}`}></div>

            <div className="absolute bottom-0 left-0 hidden">
              <svg
                className="w-2/3 ml-auto h-auto text-gray-100 placeholder-opacity-95 dark:text-slate-900"
                width="630"
                height="451"
                viewBox="0 0 630 451"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="531"
                  y="352"
                  width="99"
                  height="99"
                  fill="currentColor"
                ></rect>
                <rect
                  x="140"
                  y="352"
                  width="106"
                  height="99"
                  fill="currentColor"
                ></rect>
                <rect
                  x="482"
                  y="402"
                  width="64"
                  height="49"
                  fill="currentColor"
                ></rect>
                <rect
                  x="433"
                  y="402"
                  width="63"
                  height="49"
                  fill="currentColor"
                ></rect>
                <rect
                  x="384"
                  y="352"
                  width="49"
                  height="50"
                  fill="currentColor"
                ></rect>
                <rect
                  x="531"
                  y="328"
                  width="50"
                  height="50"
                  fill="currentColor"
                ></rect>
                <rect
                  x="99"
                  y="303"
                  width="49"
                  height="58"
                  fill="currentColor"
                ></rect>
                <rect
                  x="99"
                  y="352"
                  width="49"
                  height="50"
                  fill="currentColor"
                ></rect>
                <rect
                  x="99"
                  y="392"
                  width="49"
                  height="59"
                  fill="currentColor"
                ></rect>
                <rect
                  x="44"
                  y="402"
                  width="66"
                  height="49"
                  fill="currentColor"
                ></rect>
                <rect
                  x="234"
                  y="402"
                  width="62"
                  height="49"
                  fill="currentColor"
                ></rect>
                <rect
                  x="334"
                  y="303"
                  width="50"
                  height="49"
                  fill="currentColor"
                ></rect>
                <rect x="581" width="49" height="49" fill="currentColor"></rect>
                <rect x="581" width="49" height="64" fill="currentColor"></rect>
                <rect
                  x="482"
                  y="123"
                  width="49"
                  height="49"
                  fill="currentColor"
                ></rect>
                <rect
                  x="507"
                  y="124"
                  width="49"
                  height="24"
                  fill="currentColor"
                ></rect>
                <rect
                  x="531"
                  y="49"
                  width="99"
                  height="99"
                  fill="currentColor"
                ></rect>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
