/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Link } from "react-router-dom";
import GATracker from "../utils/useAnalyticsEventTracker";

export default function Hero(props) {
  const gaEventTracker = GATracker('Click Register Button at Home');

  const ppdb_coming_soon = props?.data?.PPDB_COMING_SOON_INFO ? JSON.parse(props?.data?.PPDB_COMING_SOON_INFO): {show: false};

  return (
    <>
      <div className="max-w-[80rem] mt-6 mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 text-center lg:text-left gap-4 md:gap-8 xl:gap-20 md:items-center">
          {ppdb_coming_soon.show ? (
            <div>
            <h2 className="text-xl font-bold text-gray-700 sm:text-4xl lg:text-3xl leading-5 dark:text-white lg:-mb-4 px-3">{ppdb_coming_soon.subtitle}</h2>
            <h1 className="text-8xl font-extrabold mb-2 text-primary sm:text-9xl md:text-9xl lg:text-[180px]">
              <span className="drop-shadow-xl hover:drop-shadow-2xl xl:inline">
                {ppdb_coming_soon.title}
              </span>
            </h1>
            <h1 className="text-xl font-bold text-gray-700 sm:text-4xl lg:text-3xl leading-5 dark:text-white px-3">
              {ppdb_coming_soon.description}{" "}
              <div className="text-primary mt-1">
                Sekolah Islam Al Akhyar Makassar
              </div>
            </h1>
            <p className="text-lg text-gray-800 dark:text-gray-400 mt-1 px-3">
              Tahun Ajaran {ppdb_coming_soon.tahun_ajaran}{" "}
            </p>

            <div className="mt-7 grid gap-3 w-full md:w-1/2 px-3">
              {props?.data?.PPDB_OPEN === "false" ? (
                <Link to="/closed">
                  <span className="flex items-center justify-center px-4 py-3 text-base font-medium text-white border border-transparent rounded-3xl shadow-md tw-full bg-primary hover:bg-primary-100 md:py-3 md:text-lg md:px-10 shadow-blue-500/50">
                    <span className="text-xl tracking-wide">
                      Pendaftaran Ditutup
                    </span>
                  </span>
                </Link>
              ) : (
                <a onClick={()=>gaEventTracker('daftar sekarang')}
                  className="inline-flex justify-center items-center gap-x-3 text-center bg-primary hover:bg-blue-700 border border-transparent lg:text-xl text-white font-medium rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800 text-base"
                  href="https://s.alakhyar.sch.id/ppdb"
                >
                  Daftar Sekarang
                  <svg
                    className="w-2.5 h-2.5"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </a>
              )}
              <img src="/img/ba-2.png" className="-mb-10 block lg:hidden" alt="dua"/>
              
            </div>
          </div>
          ) : (
            <div>
            {/* <h2 className="text-xl font-bold text-gray-700 sm:text-4xl lg:text-3xl leading-5 dark:text-white lg:-mb-4 px-3">Telah dibuka</h2> */}
            <h1 className="text-8xl font-extrabold mb-2 text-primary sm:text-9xl md:text-9xl lg:text-[180px]">
              <span className="drop-shadow-xl hover:drop-shadow-2xl xl:inline">
                PPDB
              </span>
            </h1>
            <h1 className="text-xl font-bold text-gray-700 sm:text-4xl lg:text-3xl leading-5 dark:text-white px-3">
              Penerimaan Peserta Didik Baru{" "}
              <div className="text-primary mt-1">
                Sekolah Islam Al Akhyar Makassar
              </div>
            </h1>
            <p className="text-lg text-gray-800 dark:text-gray-400 mt-1 px-3">
              Tahun Ajaran {props.data.PPDB_TAHUN_AJARAN}{" "}
            </p>

            <div className="mt-7 grid gap-3 w-full md:w-1/2 px-3">
              {props?.data?.PPDB_OPEN === "false" ? (
                <Link to="/closed">
                  <span className="flex items-center justify-center px-4 py-3 text-base font-medium text-white border border-transparent rounded-3xl shadow-md tw-full bg-primary hover:bg-primary-100 md:py-3 md:text-lg md:px-10 shadow-blue-500/50">
                    <span className="text-xl tracking-wide">
                      Pendaftaran Ditutup
                    </span>
                  </span>
                </Link>
              ) : (
                <a onClick={()=>gaEventTracker('daftar sekarang')}
                  className="inline-flex justify-center items-center gap-x-3 text-center bg-primary hover:bg-blue-700 border border-transparent lg:text-xl text-white font-medium rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800 text-base"
                  href="https://s.alakhyar.sch.id/ppdb"
                >
                  Daftar Sekarang
                  <svg
                    className="w-2.5 h-2.5"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </a>
              )}
              <img src="/img/ba-2.png" className="-mb-10 block lg:hidden" alt="dua"/>
              
            </div>
          </div>
          )}
          

          <div className="relative -mb-20 hidden lg:block">
            <img
              className="mb-1 w-2/2 transition-transform hover:-translate-x-10 duration-500"
              src="/img/ba-1.png"
              alt="fir"
            />
            <div className="absolute inset-20 -z-[1] bg-gradient-to-tr from-blue-100 via-white/0 to-white/0 w-full h-full rounded-md mt-4  -ml-4 lg:mt-6 lg:-ml-6 dark:from-slate-800 dark:via-slate-900/0 dark:to-slate-900/0"></div>

            <div className="absolute bottom-0 left-0 hidden">
              <svg
                className="w-2/3 ml-auto h-auto text-gray-100 placeholder-opacity-95 dark:text-slate-900"
                width="630"
                height="451"
                viewBox="0 0 630 451"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="531"
                  y="352"
                  width="99"
                  height="99"
                  fill="currentColor"
                />
                <rect
                  x="140"
                  y="352"
                  width="106"
                  height="99"
                  fill="currentColor"
                />
                <rect
                  x="482"
                  y="402"
                  width="64"
                  height="49"
                  fill="currentColor"
                />
                <rect
                  x="433"
                  y="402"
                  width="63"
                  height="49"
                  fill="currentColor"
                />
                <rect
                  x="384"
                  y="352"
                  width="49"
                  height="50"
                  fill="currentColor"
                />
                <rect
                  x="531"
                  y="328"
                  width="50"
                  height="50"
                  fill="currentColor"
                />
                <rect
                  x="99"
                  y="303"
                  width="49"
                  height="58"
                  fill="currentColor"
                />
                <rect
                  x="99"
                  y="352"
                  width="49"
                  height="50"
                  fill="currentColor"
                />
                <rect
                  x="99"
                  y="392"
                  width="49"
                  height="59"
                  fill="currentColor"
                />
                <rect
                  x="44"
                  y="402"
                  width="66"
                  height="49"
                  fill="currentColor"
                />
                <rect
                  x="234"
                  y="402"
                  width="62"
                  height="49"
                  fill="currentColor"
                />
                <rect
                  x="334"
                  y="303"
                  width="50"
                  height="49"
                  fill="currentColor"
                />
                <rect x="581" width="49" height="49" fill="currentColor" />
                <rect x="581" width="49" height="64" fill="currentColor" />
                <rect
                  x="482"
                  y="123"
                  width="49"
                  height="49"
                  fill="currentColor"
                />
                <rect
                  x="507"
                  y="124"
                  width="49"
                  height="24"
                  fill="currentColor"
                />
                <rect
                  x="531"
                  y="49"
                  width="99"
                  height="99"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="grid justify-center py-10 px-2 lg:px-0 p-0 bg-gray-100">
        <img
          src={`/img/ppdb.jpg`}
          alt="ppdb"
          className="shadow rounded-2xl max-w-full h-auto align-middle border-none"
        />
      </div>
    </>
  );
}
