/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  PhoneIcon,
  ChatIcon,
  AcademicCapIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link, NavLink } from 'react-router-dom'

const solutions = [
  {
    name: 'TK Islam',
    description: 'Informasi lengkap pendaftaran calon peserta didik baru TK/RA Islam Al Akhyar Islamic School',
    href: '/jenjang/tkit',
    router: true,
    icon: AcademicCapIcon,
  },
  {
    name: 'SD Islam',
    description: 'Informasi lengkap pendaftaran calon peserta didik baru SD Islam Al Akhyar Islamic School',
    href: '/jenjang/sdit',
    router: true,
    icon: AcademicCapIcon,
  },
  {
    name: 'SMP Islam',
    description: 'Informasi lengkap pendaftaran calon peserta didik baru SMP Islam Al Akhyar Islamic School',
    href: '/jenjang/smpit',
    router: true,
    icon: AcademicCapIcon,
  },
  {
    name: 'SMA Islam',
    description: 'Informasi lengkap pendaftaran calon peserta didik baru SMA Islam Al Akhyar Islamic School',
    href: '/jenjang/smait',
    router: true,
    icon: AcademicCapIcon,
  },
  // {
  //   name: 'Syarat Pendaftaran',
  //   description: 'Persyaratan pendaftaran calon peserta didik baru Al Akhyar Islamic School',
  //   href: '/syarat',
  //   router: true,
  //   icon: ShieldCheckIcon,
  // },
  // { name: 'Jenjang Pendidikan', 
  //   description: "Informasi komprehensif jenjang pendidikan mulai dari TKIT, SDIT, SMPIT, dan SMAIT", 
  //   href: '/#feature', 
  //   router: false,
  //   icon: AcademicCapIcon 
  // },
  // {
  //   name: 'Brosur Pendaftaran',
  //   description: 'Unduh brosur pendaftaran peserta didik baru',
  //   href: 'https://alakhyar.sch.id/wp-content/uploads/2021/11/PPDB-TKIT-SMAIT-AL-AKHYAR-2022-2023.pdf',
  //   router: false,
  //   icon: DownloadIcon,
  // },
]
const callsToAction = [
  { name: 'Instagram', href: 'https://www.instagram.com/p/CZJVYr_vSRy/', icon: ChatIcon },
  { name: 'Hubungi Kami', href: 'https://wa.me/6285316000950?text=*Assalamualaikum,%20saya%20ingin%20bertanya%20tentang%20sekolah%20Al%20Akhyar*', icon: PhoneIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header(props) {
  const {flag} = props
  return (
      <>
    <Popover className="relative bg-white">
      <div className="px-4 mx-auto max-w-7xl sm:px-6">
        <div className="flex items-center justify-between py-6 border-b-2 border-gray-100 border-dashed md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
            <span>
              <span className="sr-only">PPDB</span>
              <img
                className="w-auto h-8 sm:h-10"
                src="/img/logo.png"
                alt="al akhyar"
              />
            </span>
            </Link>
          </div>
          {flag?.PPDB_BROSUR_URL && (<div className="-my-2 -mr-2 md:hidden">
          <a href={flag?.PPDB_BROSUR_URL}
                className="inline-flex items-center justify-center px-5 text-xs py-2 mx-auto font-medium text-primary border border-transparent rounded-2xl shadow-md whitespace-nowrap bg-blue-100 shadow-blue-500/40"
              >
                Download Brosur 
              </a>
            {/* <img
                className="w-auto h-10 sm:h-10"
                src="/img/sekolahpenggerak.png"
                alt="al akhyar"
              /> */}
          </div>)}
          
          <Popover.Group as="nav" className="hidden space-x-7 md:flex">
            <NavLink
                className={({ isActive }) => classNames(isActive ? "text-primary shadow-md" : "text-gray-900", 'inline-flex items-center px-5 py-2 text-base font-medium rounded-xl group hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-400 focus-visible:ring-opacity-75')} 
              to={`/`}
              >
                Home
            </NavLink>
            <NavLink
                className={({ isActive }) => classNames(isActive ? "text-primary shadow-md" : "text-gray-900 ", 'inline-flex items-center px-3 py-2 text-base font-medium rounded-xl group hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-400 focus-visible:ring-opacity-75')} 
              to={`/jadwal`}
              >
                Jadwal
            </NavLink>
            <Popover className="relative">
              {({ open }) => (
                <>
                <Popover.Button
                className={classNames(
                  open ? 'text-white bg-primary' : 'text-white bg-secondary',
                  'group px-5 py-2 inline-flex items-center text-base font-medium rounded-xl hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                )}
            >
              <span>Informasi</span>
              <ChevronDownIcon
                className={classNames(
                  open ? 'text-white' : 'text-gray-50',
                  'ml-2 h-5 w-5 group-hover:text-gray-100'
                )}
                aria-hidden="true"
              />
            </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-50 w-screen max-w-md px-2 mt-3 -ml-4 transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          {solutions.map((item) => {
                            return item.router ? (<Link key={item.name} to={item.href}>
                               <span
                              className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 w-6 h-6 text-indigo-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </span>
                            </Link>):( <a
                              key={item.name}
                              href={item.href}
                              className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 w-6 h-6 text-indigo-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </a>)
                          })}
                        </div>
                        <div className="px-5 py-5 space-y-6 bg-gray-50 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                              >
                                <item.icon className="flex-shrink-0 w-6 h-6 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
          <div className="items-center justify-end hidden md:flex md:flex-1 lg:w-0">
            {props?.flag?.PPDB_OPEN === 'false' ? (
              <Link to="/closed">
              <span
               className="inline-flex items-center justify-center px-7 py-2 ml-8 text-base font-medium text-white border border-transparent rounded-2xl shadow-md whitespace-nowrap bg-primary hover:bg-primary-100 shadow-blue-500/50"
             >
               Pendaftaran Ditutup
             </span>
              </Link>
               
            ) : (
              <>
              {flag?.PPDB_BROSUR_URL && (<a href={flag?.PPDB_BROSUR_URL}
                className="inline-flex items-center justify-center px-7 py-2 mr-2 text-base font-medium text-primary border border-transparent rounded-2xl shadow-md whitespace-nowrap bg-blue-100 shadow-blue-500/40"
              >
                Download Brosur
              </a>)}
              
              {/* <a href="https://s.alakhyar.sch.id/ppdb"
                className="inline-flex items-center justify-center px-7 py-2 ml-2 text-base font-medium text-white border border-transparent rounded-2xl shadow-md whitespace-nowrap bg-primary hover:bg-primary-100 shadow-blue-500/50"
              >
                Daftar Segera
              </a> */}
              </>
            ) } 

              <a href="https://alakhyar.sch.id"
                className="inline-flex items-center justify-center px-7 py-2 ml-2 text-base font-medium text-white border border-transparent rounded-2xl shadow-md whitespace-nowrap bg-primary hover:bg-primary-100 shadow-blue-500/50"
              >
                Official Website
              </a>
           
            
          </div>
        </div>
      </div>
    </Popover>
    </>
  )
}
