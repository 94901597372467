import React from 'react';

const OfficialWebCard = (props) => {
    // const data = JSON.parse(props?.data?.PPDB_KETERANGAN || '{}')
    return (
        <section className="bg-primary/10 dark:bg-gray-900">
    <div className="grid gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <img className="w-full dark:hidden" src="/img/alakhyar.sch.id.webp" alt="dashboard" />
        <img className="w-full hidden dark:block" src="/img/alakhyar.sch.id.webp" alt="dashboard" />
        <div className="mt-4 md:mt-0">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-primary dark:text-white">Mau tau Al Akhyar Lebih lanjut? <br/>Yuk ke laman resminya.</h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">Dapatkan informasi terkini dan terlengkap mengenai Al Akhyar Islamic School Makassar.</p>
            <a href="https://alakhyar.sch.id" className="inline-flex items-center text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary">
               Official Website
                <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </a>
        </div>
    </div>
</section>

    );
};

export default OfficialWebCard;
