import Firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';

const firebaseConfig = {
    apiKey: "AIzaSyATPUDmUuAOKpzJKndRx3hPcjT3DVuakrs",
    authDomain: "mythic-tenure-167007.firebaseapp.com",
    projectId: "mythic-tenure-167007",
    storageBucket: "mythic-tenure-167007.appspot.com",
    messagingSenderId: "280963209085",
    appId: "1:280963209085:web:8e142ba03f16d1865d1e14",
    measurementId: "G-S0N8XB6TZ4"
};

export const firebase = Firebase.initializeApp(firebaseConfig);


