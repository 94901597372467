import React, { useEffect, useState } from "react";
import { useFlags } from "./utils/FlagsProvider";
import { useNavigate } from "react-router-dom"
import Hero from './components/Hero'
import Feature from './components/Feature'
import Cta from './components/Cta'
import { XCircleIcon } from '@heroicons/react/outline'
import Layout from './components/Layout'
import OfficialWebCard from "./components/_OfficialWebCard";

export default function MyApp() {
  const flags = useFlags();
  const [isOpen, setIsOpen] = useState(false)
  // const [isRemoteOpen] = useState(flags.PPDB_OPEN)
  const navigate = useNavigate();
  const onClick = () => {
    setIsOpen(!isOpen)
    navigate(`/jenjang/tkit`)
  }

  const closePopup = () => {
    setIsOpen(false)
    localStorage.setItem('closePopup', true)
  } 

  const timer = flags.PPDB_OPEN === 'false' ? 2000 : 2000
  useEffect(() => {
    setTimeout(function() {
      const getClosePopup = localStorage.getItem('closePopup') || null
      if (!getClosePopup) {
        setIsOpen(true)
      }
    }, timer);
  }, [timer])
  return (
    <Layout props={flags}>
      <Hero data={flags}/>
      <OfficialWebCard data={flags}/>
      <Feature/>
      <Cta data={flags}/>
      <div>
      { isOpen && (<div className="fixed inset-0 z-10 opacity-100 transition ease-in-out">
        <div className="absolute inset-0 z-20 bg-black/80 dark:bg-black/90"></div>
        <div className="absolute bottom-10 md:bottom-0 left-0 right-0 z-50 pointer-events-none">
            <div className="max-w-md mx-auto bg-white dark:bg-gray-800 rounded-t-2xl transition-all duration-500 transform-gpu pointer-events-auto">
            <div className="relative">
                <img className="rounded-t-2xl z-50 w-full bg-cover" src={flags.PPDB_BANNER ?? "https://fakeimg.pl/350x150?text=😊"} alt="PPDB"/>
                <h3 className={`absolute text-gray-100 top-2 right-2 ${flags.PPDB_OPEN === 'false' ? '':''}`}>
                    <XCircleIcon onClick={()=> closePopup()} className="h-8 w-8 cursor-pointer"/>
                </h3>
            </div>
                <div className="p-5">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">PPDB {flags.PPDB_TAHUN_AJARAN} {flags.PPDB_OPEN === 'false' ? 'ditutup':'dibuka'}!</h5>
                    <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">
                        {flags.PPDB_OPEN === 'false' ? 'PPDB tahun ajaran baru akan dibuka kembali setiap akhir tahun. Pantau terus informasi terkini di sosial media kami. ':'Pendidikan adalah tiket ke masa depan. Hari esok dimiliki oleh orang-orang yang mempersiapkan dirinya sejak hari ini.'}
                    </p>
                    {flags.PPDB_BROSUR_URL && ( <a href={flags.PPDB_BROSUR_URL} target="_blank" className="rounded-full border-2 mt-4 border-primary dark:border-white w-full bg-primary dark:bg-white flex justify-center items-center text-center text-white dark:text-dark_00 px-3 py-4 font-bold text-sm hover:opacity-70" rel="noopener noreferrer">Download Brosur PPDB {flags.PPDB_TAHUN_AJARAN}</a>)}
                   
                    {/* {flags.PPDB_OPEN === 'true' && (<button onClick={() => onClick()} type="button" className="rounded-full border-2 mt-4 border-primary dark:border-white w-full bg-white dark:bg-dark_00 flex justify-center items-center text-center text-primary dark:text-white px-3 py-4 font-bold text-sm hover:opacity-70">Lihat Selengkapnya</button>)} */}
                    <button onClick={() => onClick()} type="button" className="rounded-full border-2 mt-4 border-primary dark:border-white w-full bg-white dark:bg-dark_00 flex justify-center items-center text-center text-primary dark:text-white px-3 py-4 font-bold text-sm hover:opacity-70">Lihat Selengkapnya</button>
                    
                </div>
            </div>

        </div>
    </div>)}
    </div>
      </Layout>
  )
}


