import { NavLink } from "react-router-dom"
import React from 'react'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
const BottomNavigation = () => {
    return (
        <>
            {/* <!-- component --> */}
            <div className="w-full">
                {/* <!-- <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> // if shown only tablet/mobile--> */}
                {/* <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> */}
                <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
                    <div id="tabs" className="flex justify-between">
                        <NavLink
                            className={({ isActive }) => classNames(isActive ? "text-primary" : "text-gray-900", 'w-full focus:text-primary hover:text-primary justify-center inline-block text-center pt-2 pb-1')} to={`/`}
                        >
                            <svg height={25} width="25" className="inline-block mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                            <span className="tab tab-home block text-xs">Home</span>
                        </NavLink>
                        <NavLink
                            className={({ isActive }) => classNames(isActive ? "text-primary" : "text-gray-900", 'w-full focus:text-primary hover:text-primary justify-center inline-block text-center pt-2 pb-1')} to={`/jadwal`}
                        >
                            <svg width="25" height="25" className="inline-block mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                            <span className="tab tab-home block text-xs">Jadwal</span>
                        </NavLink>

                        <NavLink
                            className={({ isActive }) => classNames(isActive ? "text-primary" : "text-gray-900", 'w-full focus:text-primary hover:text-primary-100 justify-center inline-block text-center pt-2 pb-1')} to={`/jenjang/tkit`}
                        >
                            <svg className="inline-block mb-1" height="25" width="25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
                            <span className="tab tab-home block text-xs tracking-widest">TK</span>
                        </NavLink>

                        <NavLink
                            className={({ isActive }) => classNames(isActive ? "text-primary" : "text-gray-900", 'w-full focus:text-primary hover:text-primary-100 justify-center inline-block text-center pt-2 pb-1')} to={`/jenjang/sdit`}
                        >
                            <svg className="inline-block mb-1" height="25" width="25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
                            <span className="tab tab-home block text-xs tracking-widest">SD</span>
                        </NavLink>

                        <NavLink
                            className={({ isActive }) => classNames(isActive ? "text-primary" : "text-gray-900", 'w-full focus:text-primary hover:text-primary-100 justify-center inline-block text-center pt-2 pb-1')} to={`/jenjang/smpit`}
                        >
                            <svg className="inline-block mb-1" height="25" width="25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
                            <span className="tab tab-home block text-xs">SMP </span>
                        </NavLink>

                        <NavLink
                            className={({ isActive }) => classNames(isActive ? "text-primary" : "text-slate-700", 'w-full focus:text-primary hover:text-primary-100 justify-center inline-block text-center pt-2 pb-1')} to={`/jenjang/smait`}
                        >
                            <svg className="inline-block mb-1" height="25" width="25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
                            <span className="tab tab-home block text-xs">SMA</span>
                        </NavLink>
                        
                    </div>
                </section>
            </div>
        </>
    )
}

export default BottomNavigation

